// components\Settings.tsx
import React, { useState } from 'react';
import { changePassword } from './DataHandler';

function Settings() {
  const [newPassword, setNewPassword] = useState('');

  const handleChangePassword = async () => {
    try {
      const response = await changePassword(newPassword);
      alert(response.message);
    } catch (error) {
      // `error`가 `Error` 타입인지 확인 후 메시지를 출력
      const errorMessage = error instanceof Error ? error.message : '알 수 없는 오류가 발생했습니다.';
      alert(errorMessage);
    }
  };

  return (
    <div>
      <h2>설정</h2>
      <input
        type="password"
        placeholder="새 비밀번호"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <button onClick={handleChangePassword}>비밀번호 변경</button>
    </div>
  );
}

export default Settings;
