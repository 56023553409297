// components\DataHandler.tsx

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://kdi.doowon.ac.kr:1804';

// mac 에 해당하는 데이터를 가져온다.
export async function fetchDeviceByMac(mac: string) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/record`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mac }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching device data:', error);
    throw error;
  }
}

// email에 해당하는 기기들을 리턴해 준다.
export async function fetchRecordsByEmail(email: string) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/findArray`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching records:', error);
    throw error;
  }
}

export async function updateRecord(mac: string, updates: any) {
  const { _id, order, ...filteredUpdates } = updates;
  if (order !== undefined) {
    console.log(`Skipped upsert: 'order' field exists in updates for MAC: ${mac}`);
    return { message: 'Skipped due to order field presence' };
  }
  try {
    const response = await fetch(`${API_BASE_URL}/api/upsert`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mac, ...filteredUpdates }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error updating record:', error);
    throw error;
  }
}

export async function updateName(mac: string, nameArray: string[]): Promise<any> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/updateName`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mac, name: nameArray }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error updating name array:', error);
    throw error;
  }
}

export async function deleteRecord(mac: string): Promise<any> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mac }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error deleting record:', error);
    throw error;
  }
}

// Header.tsx에서 사용하는 함수
export async function checkUserExists(email: string) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/checkUser`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error checking user:', error);
    throw error;
  }
}

export async function checkPassword(email: string, password: string) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/checkPassword`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, pw: password }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error checking password:', error);
    throw error;
  }
}

export async function saveToken(email: string, token: string) {
  try {
    await fetch(`${API_BASE_URL}/api/saveToken`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, token }),
    });
  } catch (error) {
    console.error('Error saving token:', error);
    throw error;
  }
}

export async function signupUser(email: string) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/signup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return { message: '임시 비밀번호가 이메일로 전송되었습니다.' };
  } catch (error) {
    console.error('Error during signup:', error);
    throw error;
  }
}

export async function logoutUser(email: string) {
  try {
    await fetch(`${API_BASE_URL}/api/deleteToken`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    localStorage.removeItem('token');
    return { message: '로그아웃 되었습니다.' };
  } catch (error) {
    console.error('Error during logout:', error);
    throw error;
  }
}

export async function changePassword(newPassword: string) {
  try {
    const token = localStorage.getItem('token');

    if (!token) {
      throw new Error('사용자 인증 정보가 없습니다.');
    }

    const response = await fetch(`${API_BASE_URL}/api/changePassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ newPassword }),
    });

    if (!response.ok) {
      throw new Error('비밀번호 변경에 실패했습니다.');
    }
    return { message: '비밀번호가 성공적으로 변경되었습니다.' };
  } catch (error) {
    console.error('Error changing password:', error);

    let errorMessage = '서버 오류로 인해 비밀번호를 변경할 수 없습니다.';
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    throw new Error(errorMessage);
  }
}

