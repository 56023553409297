// components\DeviceDetail.tsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaCog } from 'react-icons/fa'; // react-icons 추가
import { updateRecord, updateName, fetchDeviceByMac } from './DataHandler';
import './DeviceDetail.css';
import MQTTService from './MQTTService'; // MQTTService 가져오기
import jwt_decode from 'jwt-decode'; // JWT 디코딩을 위한 라이브러리
import ModalLed from './ModalLed'; // 새롭게 추가할 모달 컴포넌트
import ModalSwitch from './ModalSwitch'; // ModalSwitch 추가
import ModalMeasure from './ModalMeasure';

interface DeviceDetailProps {
  mqttMessage: any; // MQTT 메시지 데이터 타입에 따라 수정 가능
}

interface Device {
  mac: string;
  temp?: number;
  humi?: number;
  in?: number[];
  out?: number[];
  [key: string]: any;
}

const measurementTypes = [
  { key: 'temp', label: '온도 (°C)' },
  { key: 'humi', label: '습도 (%)' },
  { key: 'light', label: '조도 (lx)' }, 
  { key: 'analog', label: 'analog (lx)' }, 
  { key: 'carbon', label: '탄소 (ppm)' },
  // 추가 항목들...
];

const DeviceDetail: React.FC<DeviceDetailProps> = ({ mqttMessage }) => {
  const { mac } = useParams<{ mac: string }>();
  const [device, setDevice] = useState<Device | null>(null);
  const [email, setEmail] = useState<string>('');
  const [editMode, setEditMode] = useState(false); // 설정 모드 상태
  const [editedNames, setEditedNames] = useState<string[]>([]);

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editedName, setEditedName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTargetIndex, setModalTargetIndex] = useState<number | null>(null);

  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState(false);
  const [selectedSwitchIndex, setSelectedSwitchIndex] = useState<number | null>(null);
  const [editedSwitchLabel, setEditedSwitchLabel] = useState('');

  const [modalState, setModalState] = useState<{ isOpen: boolean; measurement?: { key: string; label: string } }>({
    isOpen: false,
  });

  useEffect(() => {
    // 토큰에서 이메일 읽어오기
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwt_decode<{ email: string }>(token);
      setEmail(decodedToken.email);
    }
  }, []);

  useEffect(() => {
    if (mac) {
      fetchDeviceByMac(mac)
        .then((data) => {
          setDevice(data);
          setEditedNames(data.name || []);
        })
        .catch((error) => console.error('Error fetching device data:', error));
    }
  }, [mac]);

  useEffect(() => {
    if (mqttMessage && mac) {
      try {
        const parsedMessage = JSON.parse(mqttMessage);

        if (parsedMessage.mac === mac) {
          //console.log('MQTT Message for this device:', parsedMessage);
          setDevice((prevDevice) => (prevDevice ? { ...prevDevice, ...parsedMessage } : parsedMessage));

          // 업데이트된 데이터를 서버로 전송
          updateRecord(mac, parsedMessage)
            .then((response) => {
              console.log('Record updated successfully:', response);
            })
            .catch((error) => console.error('Error updating record:', error));
        }
      } catch (error) {
        console.error('Error parsing MQTT message:', error);
      }
    }
  }, [mqttMessage, mac]);

  const handleSwitchToggle = (index: number) => {
    if (!device || !device.out) return;

    const newValue = !device.out[index];
    const message = {
      mac: device.mac,
      order: 2,
      no: index,
      value: newValue,
    };

    // MQTT 메시지 전송
    const topic = `i2r/${email}/in`;
    MQTTService.getInstance().publish(topic, JSON.stringify(message));

    // 로컬 상태 업데이트
    setDevice((prevDevice) => {
      if (!prevDevice) return null;
      const updatedOut = [...(prevDevice.out || [])];
      updatedOut[index] = newValue ? 1 : 0;
      return { ...prevDevice, out: updatedOut };
    });
  };

  const handleSave = async () => {
    if (device) {
      try {
        const response = await updateName(device.mac, editedNames);
        console.log('Record updated successfully:', response);

        setDevice({ ...device, name: editedNames });
        setEditMode(false);
      } catch (error) {
        console.error('Error updating record:', error);
      }
    }
  };

  const handleCancel = () => {
    setEditedNames(device?.name || []); // 기존 값을 복원
    setEditMode(false);
  };

  const handleNameChange = (index: number, value: string) => {
    const updatedNames = [...editedNames];
    updatedNames[index] = value;
    setEditedNames(updatedNames);
  };

  //========led
  const handleLabelClick = (index: number) => {
    if (device?.name) {
      setEditedName(device.name[index + 1] || '');
    } else {
      setEditedName('');
    }
    setEditIndex(index);
  };
  
  const handleSaveLabel = (index: number) => {
    if (device?.name) {
      device.name[index + 1] = editedName;
    }
    setEditIndex(null);
  };
  

  const handleOpenModal = (index: number) => {
    if (device?.name) {
        setEditedName(device.name[index + 1] || '');
    } else {
        setEditedName('');
    }
    setModalTargetIndex(index);
    setIsModalOpen(true);
  };


  const handleModalSave = (selectedName: string) => {
    if (modalTargetIndex !== null && device && device.name) {
        const updatedNames = [...device.name];
        updatedNames[modalTargetIndex + 1] = selectedName;
        setDevice({ ...device, name: updatedNames }); // 로컬 상태 업데이트
        updateName(device.mac, updatedNames) // 서버 업데이트
            .then(() => console.log('Record updated successfully'))
            .catch((error) => console.error('Error updating record:', error));
    }
    setIsModalOpen(false);
    setModalTargetIndex(null);
  };

  //========== 스위치
  const handleSwitchLabelClick = (index: number) => {
    if (device?.name) {
      const switchLabelIndex = index + 1 + (device.in?.length || 0);
      setEditedSwitchLabel(device.name[switchLabelIndex] || `스위치 ${index + 1}`);
    } else {
      setEditedSwitchLabel('');
    }
    setSelectedSwitchIndex(index);
    setIsSwitchModalOpen(true);
  };

  const handleSwitchModalSave = (newLabel: string) => {
    if (selectedSwitchIndex !== null && device?.name) {
      const updatedNames = [...device.name];
      const switchLabelIndex = selectedSwitchIndex + 1 + (device.in?.length || 0);
      updatedNames[switchLabelIndex] = newLabel;

      setDevice({ ...device, name: updatedNames }); // 로컬 상태 업데이트
      updateName(device.mac, updatedNames) // 서버 업데이트
        .then(() => console.log('Switch label updated successfully'))
        .catch((error) => console.error('Error updating switch label:', error));
    }
    setIsSwitchModalOpen(false);
    setSelectedSwitchIndex(null);
  };

  //==============Measure
  const handleOpenMeasureModal = (type: { key: string; label: string }) => {
    setModalState({ isOpen: true, measurement: type });
  };
  
  const handleCloseMeasureModal = () => {
    setModalState({ isOpen: false });
  };

  const handleFirmwareDownload = () => {
    if (!device || !device.mac) return;
  
    const fileName = `i2r-0${device.type}.ino.bin`; // 보드 타입에 따라 파일명 생성
    const message = {
      mac: device.mac,
      order: 0,
      email: email,
      fileName: fileName
    };
  
    const topic = `i2r/${email}/in`;
    MQTTService.getInstance().publish(topic, JSON.stringify(message));
  
    console.log(`Firmware download request sent: ${JSON.stringify(message)}`);
  };
  

  if (!device) {
    return <p>Loading...</p>;
  }

  return (
    <div className="device-detail">
      <h2>
        {editedNames[0] || 'Unnamed Device'}
        <FaCog
          style={{ cursor: 'pointer', marginLeft: '10px' }}
          onClick={() => setEditMode(true)}
        />
      </h2>

      {editMode && (
        <div className="edit-names">
          {editedNames.map((name, index) => {
            let label;
            if (index === 0) {
              label = "이름";
            } else if (index <= (device?.in?.length || 0)) {
              label = `입력 ${index}`;
            } else {
              const outIndex = index - (device?.in?.length || 0);
              label = `출력 ${outIndex}`;
            }

            return (
              <div key={index}>
                <label>{label}</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleNameChange(index, e.target.value)}
                />
              </div>
            );
          })}
          <button onClick={handleSave}>저장</button>
          <button onClick={handleCancel}>취소</button>
        </div>
      )}


      {/*editMode && (
        <div className="edit-names">
          {editedNames.map((name, index) => (
            <div key={index}>
              <label>{`Name ${index}`}</label>
              <input
                type="text"
                value={name}
                onChange={(e) => handleNameChange(index, e.target.value)}
              />
            </div>
          ))}
          <button onClick={handleSave}>저장</button>
          <button onClick={handleCancel}>취소</button>
        </div>
      )*/}

      {/*
      <div>
        <h3>MQTT 메시지</h3>
        <pre>{JSON.stringify(mqttMessage, null, 2)}</pre>
      </div>
      */}

      <button onClick={handleFirmwareDownload} className="firmware-download-button">
        펌웨어 다운로드
      </button>

            
      <div>
        <div className="led-container">
            {device?.in?.map((state: number, index: number) => (
                <div key={`led-${index}`} className="led-label">
                    <p
                        onClick={() => handleOpenModal(index)}
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    >
                        {device?.name?.[index + 1] || `LED ${index + 1}`}
                    </p>
                    <div className={`led ${state === 1 ? 'led-on' : 'led-off'}`}></div>
                </div>
            ))}
        </div>
        {isModalOpen && modalTargetIndex !== null && (
            <ModalLed
                mqttMessage={mqttMessage}
                onCancel={() => setIsModalOpen(false)}
                labelNo={modalTargetIndex} // labelNo로 LED 번호 전달
                deviceMac={mac || 'Unknown MAC'} // mac이 없을 경우 기본값 제공
                name={device?.name || []} // name 배열 전달
            />
        )}
      </div>

      <div>
        <div className="switch-container">
          {device.out?.map((state, index) => (
            <div key={`switch-${index}`} className="switch-label">
              <p
                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                onClick={() => handleSwitchLabelClick(index)}
              >
                {device.name && device.name[index + 1 + (device.in?.length || 0)]
                  ? device.name[index + 1 + (device.in?.length || 0)]
                  : `스위치 ${index + 1}`}
              </p>
              <button
                className={`switch ${state === 1 ? 'switch-on' : 'switch-off'}`}
                onClick={() => handleSwitchToggle(index)}
              ></button>
            </div>
          ))}
        </div>

        {isSwitchModalOpen && selectedSwitchIndex !== null && (
          <ModalSwitch
            mqttMessage={mqttMessage}
            deviceMac={mac || 'Unknown MAC'} // mac이 없을 경우 기본값 제공
            onCancel={() => setIsSwitchModalOpen(false)}
            labelNo={selectedSwitchIndex}
            currentLabel={editedSwitchLabel}
          />
        )}
      </div>
      
      {/* 
      <div>
        <p>온도: {device.temp}°C    습도: {device.humi}%</p>
      </div>
      */}

      {measurementTypes.map(
        (type) =>
          device[type.key] !== undefined && (
            <p
              key={type.key}
              onClick={() => handleOpenMeasureModal(type)}
              style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
            >
              {type.label}: {device[type.key]}
            </p>
          )
      )}

            
      {modalState.isOpen && modalState.measurement && (
        <ModalMeasure
          type={modalState.measurement.key}
          value={device[modalState.measurement.key]}
          label={modalState.measurement.label}
          deviceMac={device.mac}
          mqttMessage={mqttMessage} // 추가된 부분
          onCancel={handleCloseMeasureModal} // 추가된 부분
        />
      )}



    </div>
  );
};

export default DeviceDetail;
